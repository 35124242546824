<template>
  <div class="info-card">
    <div class="line-1">
      <div>{{ count }}</div>
      <div class="sup-digit" v-if="supCount">{{ supCount }}</div>
    </div>
    <div class="line-2">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "InfoCard",
  components: {},
  props: {
    title: String,
    count: Number,
    supCount: Number,
  },
};
</script>

<style scoped>
.info-card {
  width: 118px;
  height: 62px;
  background-color: #e9ecef;
  border-radius: 12px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.info-card .line-1 {
  font-size: 24px;
  font-weight: 500;
  display: flex;
}
.sup-digit {
  font-size: 10px;
  font-weight: 400;
  width: 16px;
  height: 16px;
  background-color: #212529;
  color: #e9ecef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-card .line-2 {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
</style>
