<template>
<div id="app">
    <div id="app-header"> 
      <div class="title">BLOC Bot</div>
      <div class="flex-grow-1"></div>
      <div class="icon-button"><img src="@/assets/svg/notify.svg" height="20" width="20"/></div>
      <div class="icon-button"><img src="@/assets/svg/profile.svg" height="20" width="20"/></div>
    </div> 
    <div id="app-content"> 
      <MainPage/>
    </div> 
    <div id="app-footer"> 
      <div><img src="@/assets/svg/home.svg" height="20" width="20"/></div>
      <div><img src="@/assets/svg/box.svg" height="20" width="20" class="disabled-svg"/></div>
      <div><img src="@/assets/svg/profile.svg" height="20" width="20" class="disabled-svg"/></div>
    </div>
</div>
</template>

<script>
import MainPage from './components/view/MainPage.vue'

export default {
  components: { MainPage },
  name: 'App',

}
</script>

<style scoped>
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  height:100%; 
  width:100%;  
}

#app-header{
  position:absolute; 
  top:5px; 
  left:10px; 
  height:32px; 
  right:10px;
  overflow:hidden;

  display: flex;
  align-items: center;
  gap: 8px;
}

#app-header .title{
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

#app-content{
  position:absolute; 
  top:57px; 
  bottom:0; 
  left:0px; 
  right:0px; 
  overflow:hidden;
  height: calc(100vh - 58px);
}

#app-footer{
  position:absolute; 
  bottom:5px; 
  height:52px; 
  left:20px; 
  right:20px; 
  overflow:hidden;
  z-index: 1;
  background-color: #F8F9FA;
  border-radius: 12px;
  box-shadow: 0px 5px 10px 0px #2424240A
  ,0px 19px 19px 0px #2424240A
  ,0px 43px 26px 0px #24242405
  ,0px 76px 30px 0px #24242403
  ,0px 119px 33px 0px #24242400;

  display: flex;
  justify-content: space-around;
  align-items: center
}

#app-footer div:has(img){
  height: 20px;
  width: 20px;
}

</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.disabled-svg{
  filter: brightness(0) saturate(100%) invert(91%) sepia(10%) saturate(144%) hue-rotate(169deg) brightness(94%) contrast(89%);;
}
.flex-grow-1{
  flex-grow: 1;
}

/* Group 8 */
.icon-button{
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #E9ECEF;
}

.icon-button img{
  height: 20px;
  width: 20px;
}


</style>