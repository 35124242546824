<template>
  <div id="main-page">
    <div class="title">Добрый день #Имя</div>
    <div class="info-line">
      <InfoCard title="объектов в работе" :count="6" :sup-count="-2" />
      <InfoCard title="объектов на согласовании" :count="2" />
      <InfoCard title="объектов сдано" :count="4" />
    </div>
    <div class="c-flex">
      <div class="sub-title">Ваши объекты</div>
      <div class="c-flex-grow"></div>
      <div class="chip-btn">все проекты</div>
    </div>
    <div class="list-wrapper">
      <div class="card-list">
        <ObjectListItem
          v-for="(item, index) of items"
          :key="index"
          :title="item.title"
          :stage="item.stage"
          :status="item.status"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ObjectListItem from "../ui/ObjectListItem.vue";
import InfoCard from "../ui/InfoCard.vue";

export default {
  name: "MainPage",
  components: { InfoCard, ObjectListItem },
  data() {
    return {
      items: [
        {
          title: "Догма_Самолёт_Литер55_кв25",
          stage: "1 этап",
          status: "в работе",
        },
        {
          title: "Догма_Самолёт_Литер55_кв26",
          stage: "2 этап",
          status: "в работе",
        },
        {
          title: "Догма_Самолёт_Литер55_кв27",
          stage: "3 этап",
          status: "согласование",
        },
        {
          title: "Догма_Самолёт_Литер55_кв28",
          stage: "4 этап",
          status: "в работе",
        },
        {
          title: "Догма_Самолёт_Литер55_кв29",
          stage: "4 этап",
          status: "в работе",
        },
        {
          title: "Догма_Самолёт_Литер55_кв30",
          stage: "4 этап",
          status: "в работе",
        },
        {
          title: "Догма_Самолёт_Литер55_кв31",
          stage: "4 этап",
          status: "в работе",
        },
        {
          title: "Догма_Самолёт_Литер55_кв32",
          stage: "4 этап",
          status: "в работе",
        },
        {
          title: "Догма_Самолёт_Литер55_кв33",
          stage: "4 этап",
          status: "в работе",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#main-page {
  margin: 10px 10px 0 10px;
  background-color: #F8F9FA;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4px;
  border-radius: 12px;
  -webkit-box-shadow: 1px -2px 18px -5px rgba(36,36,36,0.75);
  -moz-box-shadow: 1px -2px 18px -5px rgba(36,36,36,0.75);
  box-shadow: 1px -2px 18px -5px rgba(36,36,36,0.75);


  .title {
    font-size: 24px;
    font-weight: 500;
    text-align: left;
  }

  .info-line {
    display: flex;
    gap: 10px;
  }

  .sub-title {
    font-size: 22px;
    font-weight: 500;
  }

  .c-flex {
    display: flex;
  }
  .c-flex-grow {
    flex-grow: 1;
  }

  .chip-btn {
    background-color: #6c757d;
    border-radius: 12px;
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 400;
    color: #f8f9fa;
  }

  .list-wrapper{
    position: relative;
    width: 100%;
    height: calc(100vh - 156px);
    overflow: auto;
  }

  .card-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 132px;
  }
}
</style>
