<template>
  <div class="object-list-item">
    <div class="line-1">{{ title }}</div>
    <div class="line-2">
      <div class="chip-btn light">{{ stage }}</div>
      <div class="chip-btn">{{ status }}</div>
    </div>
    <div class="line-3">
      Подробнее <img src="@/assets/svg/arrow-left.svg" alt="next" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ObjectListItem",
  components: {},
  props: {
    title: String,
    stage: String,
    status: String,
  },
};
</script>

<style lang="scss" scoped>
.object-list-item {
  background-color: #e9ecef;
  border-radius: 12px;
  height: 72px;
  padding: 12px;

  display: grid;
  grid-template-rows: max-content auto max-content;
  gap: 4px;

  .line-1 {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }
  .line-2 {
    font-size: 12px;
    font-weight: 400;

    display: flex;
    align-items: start;
    gap: 8px;
  }
  .line-3 {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
  }

  .chip-btn {
    background-color: #6c757d;
    border-radius: 12px;
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 400;
    color: #f8f9fa;

    &.light {
      background-color: #ced4da;
      color: #6c757d;
    }
  }
}
</style>
